import React from 'react'
import {Redirect, Route} from "react-router-dom";
import {isAdmin, isAuthenticated} from "reducers/auth";
import LoginLayout from "./layouts/LoginLayout";
import BaseLayout from './layouts/Base';
import EmptyLayout from './layouts/Empty';
import MainLayout from "./layouts/Main";
import ActionLog from './components/ActionLog';
import {PersonList} from './components/Person';
import Login from "./components/Auth/Login";
import KitList from './components/Kit/List';
import Dashboard from './components/Dashboard';
import ProviderRequest from './components/ProviderRequest';
import {Account, Sample} from './components';
import {PatientApplication, PatientDetail, PatientList, PatientListArchive, ReportDownload} from "./components/Patient"
import {SalivaSampleItem, SalivaSampleList} from "./components/SalivaSample";
import {AuthDistributor} from "./components/RBAC";
import {ProviderDetail, ProviderList} from './components/Provider';
import {ClinicianList} from './components/Clinician';
import {NurseList} from './components/Nurse';
import {PatientBill} from "./components/Bill";
import RolesPage from 'components/RolesPage/Page';
import UsersPage from 'components/UsersPage/Page';
import CaseList from './components/Case/List';
import CaseDetail from 'v2.0/components/Case/Detail';
import ClinicMap from './components/ClinicMap';
// import axios from "axios";
// import {getToken} from "auth/token";
import App from './App';
import {v4 as uuidv4} from 'uuid';

const defaultLayout = MainLayout;

const listRoutes = [
  {
    key: 'Login',
    path: '/login/',
    component: Login,
    protected: false,
    layout: LoginLayout
  },
  {
    key: 'Home',
    path: '/',
    component: PatientList,
    protected: true
  },
  {
    key: 'SampleList',
    path: '/sample',
    component: Sample,
    protected: true
  },
  {
    key: 'SampleDetail',
    path: '/sample/:id',
    component: Sample,
    protected: true
  },
  {
    key: 'SalivaSampleList',
    path: '/saliva-samples',
    component: SalivaSampleList,
    protected: true
  },
  {
    key: 'SalivaSample',
    path: '/saliva-samples/:id',
    component: SalivaSampleItem,
    protected: true
  },
  {
    key: 'Patient',
    path: '/patients',
    component: PatientList,
    protected: true
  },
  {
    key: 'Patient',
    path: '/patients/:id',
    component: PatientDetail,
    protected: true
  },
  {
    key: 'PatientsArchived',
    path: '/patients-archive',
    component: PatientListArchive,
    protected: true
  },
  {
    key: 'Clinician',
    path: '/providers',
    component: ClinicianList,
    protected: true
  },
  {
    key: 'Nurse',
    path: '/nurses',
    component: NurseList,
    protected: true
  },
  {
    key: 'CasesList',
    path: '/cases',
    component: CaseList,
    protected: true
  },
  {
    key: 'Cases',
    path: '/cases/:id',
    component: CaseDetail,
    protected: true
  },
  {
    key: 'Settings',
    path: '/settings',
    component: Account,
    protected: true
  },
  {
    key: 'Roles',
    path: '/roles',
    component: RolesPage,
    protected: true
  },
  {
    key: 'Users',
    path: '/users',
    component: UsersPage,
    protected: true
  },
  {
    key: 'Kits',
    path: '/kits',
    component: KitList,
    protected: true
  },
  {
    key: 'PatientApplication',
    path: '/pa/:id',
    component: PatientApplication,
    protected: false,
    layout: BaseLayout
  },
  {
    key: 'ProviderRequest',
    path: '/provider-request',
    component: ProviderRequest,
    protected: false,
    layout: EmptyLayout
  },
  {
    key: 'ProvidersList',
    path: '/clinics',
    component: ProviderList,
    protected: true,
    auth: AuthDistributor
  },
  {
    key: 'ProviderDetail',
    path: '/clinics/:id',
    component: ProviderDetail,
    protected: true,
    auth: AuthDistributor
  },
  {
    key: 'ActionLog',
    path: '/action-log/',
    component: ActionLog,
    protected: true
  },
  {
    key: 'Dashboard',
    path: '/dashboard/',
    component: Dashboard,
    protected: true
  },
  {
    key: 'PatientBill',
    path: '/patient-bill/:id',
    component: PatientBill,
    protected: false,
    layout: EmptyLayout
  },
  {
    key: 'PersonList',
    path: '/person',
    component: PersonList,
    protected: true
  },
  {
    key: 'ClinicMap',
    path: '/map',
    component: ClinicMap,
    protected: true,
    admin_only: true
  },
  {
    key: 'ReportDownload',
    path: '/report/final/download/:patientId/:reportId?',
    component: ReportDownload,
    protected: true,
  },
];

export default ({store}) => {
  return listRoutes.map((route, i) => {
    const Layout = route.layout ? route.layout : defaultLayout;
    return (
      <Route exact path={route.path} key={'route-' + i} render={
        (r) => {
          if (isAuthenticated(store.getState()) && route.path === '/login') {
            return <Redirect to={{pathname: '/'}}/>
          }

          if (!isAdmin(store.getState()) && route.admin_only) {
            return <Redirect to={{pathname: '/'}}/>
          }

          return (
            route.protected && !isAuthenticated(store.getState()) ?
              <Redirect to={{pathname: '/login', nextUrl: r.match.url}}/> :
              <App store={store}>
                <Layout route={r} component={route.component} key={uuidv4()}/>
              </App>
          )
        }
      }/>
    );
  })
};
