import React, {Component} from 'react';
import {connect} from 'react-redux';
import {deletePatient, getPatientList, PATIENT_LIST_FAILURE, submitPatient} from 'actions/patient';
import {getProviderDetails} from "actions/provider";
import {getClinicianList} from "actions/clinician";
import {getNurseList} from "actions/nurse";
import TablePlaceholder from 'components/Placeholders/TablePlaceholder';
import {cardModalOpen, patientModalOpen} from "actions/modal";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table
} from "reactstrap";
import {Page, PageContent} from 'components/Views';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  IfAuthM2,
  IfAuthSuper2,
  isAuthDistributor,
  isAuthProvider,
  isAuthSuperAdminOrReadonly,
  isAuthSuper,
  IfNotAuthSuperReadonly,
  IfAuthSuperAdminOrReadonly
} from "components/RBAC";
import {Link, withRouter} from "react-router-dom";
import URI from 'urijs';
import FormModal from '../Form/Modal';
import ReactPaginate from 'react-paginate';

import moment from 'moment-timezone';
import {Classes, Dialog} from "@blueprintjs/core";
import Form from "components/Patient/Form/CaseForm";
import {call_delete_token} from "../../../utils/delete_token_call";

const isVisibleForGPOnly = caseStatus => {
    let statuses = ['gc_scheduled', 'embryo_samples_received', 'case_accepted', 'setup_complete', 'report_ready', 'report_hold', 'sample_hold'];
    return statuses.includes(caseStatus)
}

export default connect(
  state => ({
    user: state.auth,
    items: state.patient.items,
    items_total: state.patient.items_total,
    pages: state.patient.pages,
    page_size: state.patient.page_size,
    archived_items: state.patient.archived_items,
    patient: state.patient.item,
    selected_provider_id: state.common.selected_provider_id,
    providers: state.provider.items
  }),
  {
    getList: getPatientList,
    patientModalOpen: patientModalOpen,
    deletePatient: deletePatient,
    cardModalOpen: cardModalOpen,
    submitPatient: submitPatient,
    getClinicianList: getClinicianList,
    getNurseList: getNurseList,
    getProviderDetails: getProviderDetails
  }
)(
  withRouter(
    class extends Component {
      constructor(props) {
        super(props);
        this.loadItems = this.loadItems.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.setCaseStatusFilter = this.setCaseStatusFilter.bind(this);
        this.updateState = this.updateState.bind(this);
        this.onCaseStatusFilterChange = this.onCaseStatusFilterChange.bind(this);
        this.onCaseTypeFilterChange = this.onCaseTypeFilterChange.bind(this);
        this.setCaseTypeFilter = this.setCaseTypeFilter.bind(this);
        this.setConsentSignedFlag = this.setConsentSignedFlag.bind(this)
        this.setInvoicePaidFlag = this.setInvoicePaidFlag.bind(this)
        this.onFilterApply = this.onFilterApply.bind(this);
        this.onFilterClear = this.onFilterClear.bind(this);
        this.addToInitial = this.addToInitial.bind(this);

        const initCaseStatusFilter = localStorage.getItem('caseStatusFilter') ?
            JSON.parse(localStorage.getItem('caseStatusFilter')) : [];
        const initCaseTypesFilters = localStorage.getItem('caseTypesFilters') ?
            JSON.parse(localStorage.getItem('caseTypesFilters')) : ['a', 'a_plus', 'm', 'p', 'sr', 'm2'];
        const initConsentSignedFlag = localStorage.getItem('consentSigned') ?
            (localStorage.getItem('consentSigned') === 'Y') : null;
        const initInvoicePaidFlag = localStorage.getItem('invoicePaid') ?
            (localStorage.getItem('invoicePaid') === 'Y') : null;

        this.state = {
          modalIsOpen: false,
          case_id: null,
          case_status_filter: initCaseStatusFilter,
          case_type_filter: initCaseTypesFilters,
          case_status_dropdown_open: false,
          consentSignedFlag: initConsentSignedFlag,
          invoicePaidFlag: initInvoicePaidFlag,
          current_page: null,
          newCaseModalIsOpen: false,
          clinicianList: [],
          nurseList: [],
          newCaseValues: {
            case_id: '',
          },
          providerDefaults: {},
          initialValues: {},
          overrideDefaultValues: {},
          responseErrorMessages: {}
        }
      }

      componentDidMount() {
        let provider = JSON.parse(window.localStorage.getItem('provider'))
        if (provider) {
          this.props.getProviderDetails(provider.id).then((resp) => {
            if (resp.type === '@@provider/PROVIDER_GET_DETAILS_SUCCESS') {
              this.addToInitial(resp.payload.include_embryo_id, resp.payload.include_embryo_grade, resp.payload.include_biopsy_day, resp.payload.show_cycle_number)
            }
          }).finally(()=>this.updateState())
        }
        else this.updateState();
      }

      addToInitial(id, grade, biopsy_day, cycleNumber) {
        this.setState({
          ...this.state,
          providerDefaults: { report_embryo_id: id, report_embryo_grade: grade, report_biopsy_day: biopsy_day, report_cycle_number: cycleNumber }
        })
      }

      updateState() {
        const search = this.props.route.location.search;
        const url = new URI(search);
        const params = url.search(true);
        const current_page = url.p ? parseInt(url.p) : 1;

        const {status, type} = params;

        this.setState({
          ...this.state,
          current_page: current_page,
          case_status_filter: status ? status.split(',') : localStorage.getItem('caseStatusFilter') ?
              JSON.parse(localStorage.getItem('caseStatusFilter')) : [],
          case_type_filter: type ? type.split(',') : localStorage.getItem('caseTypesFilters') ?
              JSON.parse(localStorage.getItem('caseTypesFilters')) : ['a', 'a_plus', 'm', 'p', 'sr', 'm2'],
          consentSignedFlag: localStorage.getItem('consentSigned') ?
            (localStorage.getItem('consentSigned') === 'Y') : null,
          invoicePaidFlag: localStorage.getItem('invoicePaid') ?
            (localStorage.getItem('invoicePaid') === 'Y') : null,
        }, () => {
          this.props.getClinicianList()
            .then((resp) => {
              if (resp.type === '@@clinician/CLINICIAN_LIST_SUCCESS') {
                this.setState({
                  ...this.state,
                  clinicianList: resp.payload,
                });
                this.props.getNurseList()
                  .then((resp) => {
                    if (resp.type === '@@nurse/NURSE_LIST_SUCCESS') {
                      this.setState({
                        ...this.state,
                        nurseList: resp.payload,
                      });
                    }
                  })
              }
            })
            .finally(() => this.loadItems())
        });
      }

      onCaseStatusFilterChange() {
        const status_filter = this.state.case_status_filter.join(',');
        const search = this.props.route.location.search;
        const url = new URI(search);
        url.removeSearch('status');
        url.removeSearch('p');
        url.addSearch('p', 1);

        if (status_filter) {
          url.addSearch('status', status_filter)
        }

        url.normalizeSearch();

        window.location.href = url.toString();
      }

      onCaseTypeFilterChange() {
        const status_filter = this.state.case_type_filter.join(',');
        const search = this.props.route.location.search;
        const url = new URI(search);
        url.removeSearch('type');
        url.removeSearch('p');
        url.addSearch('p', 1);

        if (status_filter) {
          url.addSearch('type', status_filter)
        }

        url.normalizeSearch();

        window.location.href = url.toString();
      }

      onFilterApply() {
        const status_filter = this.state.case_status_filter.join(',');
        const type_filter = this.state.case_type_filter.join(',');
        const search = this.props.route.location.search;
        const url = new URI(search);
        url.removeSearch('type');
        url.removeSearch('status');
        url.removeSearch('p');
        url.removeSearch('consentSigned');
        url.removeSearch('invoicePaid');
        url.addSearch('p', 1);

        if (type_filter) {
          url.addSearch('type', type_filter)
        }

        if (status_filter) {
          url.addSearch('status', status_filter)
        }

        if (this.state.consentSignedFlag !== null) {
          url.addSearch('consentSigned', (this.state.consentSignedFlag ? 'Y' : 'N'));
          window.localStorage.setItem('consentSigned', (this.state.consentSignedFlag ? 'Y' : 'N'));
        } else {
          window.localStorage.removeItem('consentSigned');
        }

        if (this.state.invoicePaidFlag !== null) {
          url.addSearch('invoicePaid', (this.state.invoicePaidFlag ? 'Y' : 'N'));
          window.localStorage.setItem('invoicePaid', (this.state.invoicePaidFlag ? 'Y' : 'N'));
        } else {
          window.localStorage.removeItem('invoicePaid');
        }

        url.normalizeSearch();

        if (this.state.case_status_filter.length) {
          window.localStorage.setItem('caseStatusFilter', JSON.stringify(this.state.case_status_filter));
        } else {
          window.localStorage.removeItem('caseStatusFilter');
        }

        if (this.state.case_type_filter.length) {
          window.localStorage.setItem('caseTypesFilters', JSON.stringify(this.state.case_type_filter));
        } else {
          window.localStorage.removeItem('caseTypesFilters');
        }

        window.location.href = url.toString();
      }

      onFilterClear() {
        const search = this.props.route.location.search;
        const url = new URI(search);
        url.removeSearch('type');
        url.removeSearch('status');
        url.removeSearch('p');
        url.removeSearch('consentSigned');
        url.removeSearch('invoicePaid');
        url.addSearch('p', 1);

        url.normalizeSearch();

        window.localStorage.removeItem('caseStatusFilter');
        window.localStorage.removeItem('caseTypesFilters');
        window.localStorage.removeItem('consentSigned');
        window.localStorage.removeItem('invoicePaid');
        window.location.href = url.toString();
      }

      setCaseStatusFilter(value) {
        let res = this.state.case_status_filter.slice();

        if (value !== 'any') {
          if (res.includes(value)) {
            res.splice(res.indexOf(value), 1);
          } else {
            res.push(value)
          }
        } else {
          res = []
        }

        this.setState({
          ...this.state,
          case_status_filter: res,
          case_status_dropdown_open: false
        }, () => {
          //this.onCaseStatusFilterChange();
        })
      }

      setCaseTypeFilter(value) {
        let res = this.state.case_type_filter.slice();

        if (res.includes(value)) {
          res.splice(res.indexOf(value), 1);
        } else {
          res.push(value)
        }

        this.setState({
          ...this.state,
          case_type_filter: res,
        }, () => {
          //this.onCaseTypeFilterChange();
        })
      }

      setConsentSignedFlag(v) { this.setState({ ...this.state, consentSignedFlag: v }) }
      setInvoicePaidFlag(v) { this.setState({ ...this.state, invoicePaidFlag: v }) }

      loadItems(page = null, filter) {
        const search = this.props.route.location.search;
        let url = new URI(search);
        const params = url.search(true);

        let {p: url_page, ...other_url_params} = params;

        if (!('type' in other_url_params) && !('status' in other_url_params)) {
          if (localStorage.getItem('caseStatusFilter')) {
            other_url_params.status = JSON.parse(localStorage.getItem('caseStatusFilter')).join(',')
          }

          if (localStorage.getItem('caseTypesFilters')) {
            other_url_params.type = JSON.parse(localStorage.getItem('caseTypesFilters')).join(',')
          }

          if (localStorage.getItem('consentSigned')) {
            other_url_params.consentSigned = localStorage.getItem('consentSigned');
          }

          if (localStorage.getItem('invoicePaid')) {
            other_url_params.invoicePaid = localStorage.getItem('invoicePaid');
          }
        }

        const data = {page: page ? page : this.state.current_page, ...other_url_params};
        if (this.state.case_id) {
          data.case_id = this.state.case_id
        }

        this.props.getList(data)
          .then((resp) => {
            if (resp.type === PATIENT_LIST_FAILURE) {
              const token = this.props.user.token;
              call_delete_token(token)
            }
          });
      }

      onSearchChange(value) {

      }

      render() {
        const {items, archived_items} = this.props;
        const submit = async (values) => {
          return await this.props.submitPatient(values);
        };

        const onOverrideDefaultValues = (key, val) => {
          this.setState({...this.state, overrideDefaultValues: {...this.state.overrideDefaultValues, [key]: val}})
        }

        const handleResponse = (data) => {
          if (data.type === '@@patient/PATIENT_CREATE_FAILURE') {
            this.setState({...this.state, responseErrorMessages: data.payload.response})
          }
          if (data.type === '@@patient/PATIENT_CREATE_SUCCESS') {
            if (Object.keys(this.state.overrideDefaultValues).length) {
              const newVals = Object.assign(data.payload, this.state.overrideDefaultValues);
              this.setState({...this.state, overrideDefaultValues: {}})
              submit(newVals)
            }
            this.props.history.push(`/patients/${data.payload.id}?provider=${data.payload.provider}`)
            this.setState({...this.state, responseErrorMessages: {}})
          }
        };

        return (
          <Page>
            <FormModal
              isOpen={this.state.modalIsOpen}
              close={() => {
                this.setState({...this.state, modalIsOpen: false})
              }}
            />

            <div className="page-header" style={{marginBottom:'5px'}}>
              <Row className="align-items-center justify-content-between">
                <Col md="auto">
                  <h3 className="page-title">Cases</h3>
                  {
                    archived_items && archived_items.length > 0 &&
                    <p><Link to="/patients-archive">View Archive ({archived_items.length})</Link></p>
                  }
                </Col>

                {/*<Col md={4}>*/}
                {/*  <form onSubmit={(e) => {*/}
                {/*    e.preventDefault();*/}
                {/*    this.loadItems(1);*/}
                {/*  }}>*/}
                {/*    <InputGroup>*/}
                {/*      <Input*/}
                {/*        type="text"*/}
                {/*        placeholder="Label, Name..."*/}
                {/*        value={this.state.case_id}*/}
                {/*        disabled={!items || items.length === 0}*/}
                {/*        onChange={(e) => {*/}
                {/*          const val = e.target.value;*/}
                {/*          this.setState({...this.state, case_id: val});*/}
                {/*        }}*/}
                {/*      />*/}

                {/*      <InputGroupAddon addonType="append">*/}
                {/*        <Button*/}
                {/*          type="submit">*/}
                {/*          Search*/}
                {/*        </Button>*/}
                {/*        <Button*/}
                {/*          hidden={!this.state.case_id}*/}
                {/*          onClick={() => {*/}
                {/*            this.setState({...this.state, case_id: ''}, () => {*/}
                {/*              //clearTimeout(search_timeout);*/}
                {/*              this.loadItems(1);*/}
                {/*            });*/}
                {/*          }}><FontAwesomeIcon icon="times"/></Button>*/}
                {/*      </InputGroupAddon>*/}

                {/*    </InputGroup>*/}
                {/*  </form>*/}
                {/*</Col>*/}

                <Col md="auto">
                  <IfNotAuthSuperReadonly>
                    <Button
                      color="light"
                      onClick={() => {
                        if (isAuthSuper(this.props.user)) {
                          const provider = window.localStorage.getItem('provider');
                          if (provider) {
                            //submit({}).then(handleResponse);
                            this.setState({...this.state, newCaseModalIsOpen: true})
                          } else {
                            alert('Please select clinic first');
                          }
                        } else {
                          if (isAuthDistributor(this.props.user) && this.props.providers && this.props.providers.length <= 0) {
                            alert('Create clinic first');
                          } else {
                            //this.props.patientModalOpen()
                            //this.setState({...this.state, modalIsOpen: true})
                            if (isAuthProvider(this.props.user)) {
                              submit({}).then(handleResponse);
                            } else {
                              const provider = window.localStorage.getItem('provider');

                              if (provider) {
                                submit({}).then(handleResponse);
                              } else {
                                alert('Please select clinic first');
                              }
                            }
                          }
                        }
                      }}
                    >
                      <FontAwesomeIcon icon="plus"/>{' '}Create
                    </Button>
                  </IfNotAuthSuperReadonly>
                </Col>
              </Row>

              <Row className="align-items-center" style={{marginTop: 20}}>
                <Col md="auto">
                  <Row>
                    <Col md="auto">
                      <span className="text-uppercase" style={{paddingRight: 10}}>Test Type:</span>
                    </Col>
                    <Col>
                      <FormGroup check inline>
                        <Label check style={{cursor: 'pointer'}}>
                          <Input
                            type="checkbox"
                            checked={this.state.case_type_filter.includes('a')}
                            onChange={(e) => this.setCaseTypeFilter('a')}
                          /> A
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label check style={{cursor: 'pointer'}}>
                          <Input
                            type="checkbox"
                            checked={this.state.case_type_filter.includes('a_plus')}
                            onChange={(e) => this.setCaseTypeFilter('a_plus')}
                          /> A+
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label check style={{cursor: 'pointer'}}>
                          <Input
                            type="checkbox"
                            checked={this.state.case_type_filter.includes('m')}
                            onChange={(e) => this.setCaseTypeFilter('m')}
                          /> M
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label check style={{cursor: 'pointer'}}>
                          <Input
                            type="checkbox"
                            checked={this.state.case_type_filter.includes('p')}
                            onChange={(e) => this.setCaseTypeFilter('p')}
                          /> P
                        </Label>
                      </FormGroup>

                      <FormGroup check inline>
                        <Label check style={{cursor: 'pointer'}}>
                          <Input
                            type="checkbox"
                            checked={this.state.case_type_filter.includes('sr')}
                            onChange={(e) => this.setCaseTypeFilter('sr')}
                          /> SR
                        </Label>
                      </FormGroup>

                      <IfAuthM2>
                        <FormGroup check inline>
                          <Label check style={{cursor: 'pointer'}}>
                            <Input
                              type="checkbox"
                              checked={this.state.case_type_filter.includes('m2')}
                              onChange={(e) => this.setCaseTypeFilter('m2')}
                            /> M2
                          </Label>
                        </FormGroup>
                      </IfAuthM2>
                    </Col>
                  </Row>
                </Col>

                <Col md="auto">
                  <Dropdown
                    isOpen={this.state.case_status_dropdown_open}
                    toggle={() => this.setState({
                      ...this.state,
                      case_status_dropdown_open: !this.state.case_status_dropdown_open
                    })}
                  >
                    <DropdownToggle caret color="light">
                      Status:{' '}
                      {
                        (this.state.case_status_filter && this.state.case_status_filter.length) ? (
                          <span>{this.state.case_status_filter.join(', ')}</span>
                        ) : 'Any'
                      }
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>Case Status</DropdownItem>
                      <DropdownItem>
                        <div onClick={(e) => {
                          e.stopPropagation();
                          this.setCaseStatusFilter('any');
                        }}>
                          Any
                        </div>
                      </DropdownItem>
                      <DropdownItem divider/>
                      <DropdownItem
                        active={this.state.case_status_filter.includes('pending')}
                      >
                        <div onClick={(e) => {
                          e.stopPropagation();
                          this.setCaseStatusFilter('pending');
                        }}>
                          Pending
                        </div>
                      </DropdownItem>

                      <DropdownItem
                        active={this.state.case_status_filter.includes('processing')}
                      >
                        <div onClick={(e) => {
                          e.stopPropagation();
                          this.setCaseStatusFilter('processing');
                        }}>
                          Processing
                        </div>
                      </DropdownItem>

                      <DropdownItem
                        active={this.state.case_status_filter.includes('complete')}
                      >
                        <div onClick={(e) => {
                          e.stopPropagation();
                          this.setCaseStatusFilter('complete');
                        }}>
                          Complete
                        </div>
                      </DropdownItem>

                      <DropdownItem
                        active={this.state.case_status_filter.includes('canceled')}
                      >
                        <div onClick={(e) => {
                          e.stopPropagation();
                          this.setCaseStatusFilter('canceled');
                        }}>
                          Canceled
                        </div>
                      </DropdownItem>

                      <IfAuthSuperAdminOrReadonly>
                        <DropdownItem
                          active={this.state.case_status_filter.includes('sample_hold')}
                        >
                          <div onClick={(e) => {
                            e.stopPropagation();
                            this.setCaseStatusFilter('sample_hold');
                          }}>
                            Sample hold
                          </div>
                        </DropdownItem>

                        <DropdownItem
                          active={this.state.case_status_filter.includes('report_hold')}
                        >
                          <div onClick={(e) => {
                            e.stopPropagation();
                            this.setCaseStatusFilter('report_hold');
                          }}>
                            Report hold
                          </div>
                        </DropdownItem>
                        <DropdownItem
                          active={this.state.case_status_filter.includes('report_ready')}
                        >
                          <div onClick={(e) => {
                            e.stopPropagation();
                            this.setCaseStatusFilter('report_ready');
                          }}>
                            Report ready
                          </div>
                        </DropdownItem>
                        <DropdownItem
                          active={this.state.case_status_filter.includes('case_accepted')}
                        >
                          <div onClick={(e) => {
                            e.stopPropagation();
                            this.setCaseStatusFilter('case_accepted');
                          }}>
                            Case Accepted
                          </div>
                        </DropdownItem>
                        <DropdownItem
                          active={this.state.case_status_filter.includes('gc_scheduled')}
                        >
                          <div onClick={(e) => {
                            e.stopPropagation();
                            this.setCaseStatusFilter('gc_scheduled');
                          }}>
                            GC Scheduled
                          </div>
                        </DropdownItem>
                        <DropdownItem
                          active={this.state.case_status_filter.includes('setup_complete')}
                        >
                          <div onClick={(e) => {
                            e.stopPropagation();
                            this.setCaseStatusFilter('setup_complete');
                          }}>
                            Set Up Complete
                          </div>
                        </DropdownItem>
                        <DropdownItem
                          active={this.state.case_status_filter.includes('embryo_samples_received')}
                        >
                          <div onClick={(e) => {
                            e.stopPropagation();
                            this.setCaseStatusFilter('embryo_samples_received');
                          }}>
                            Embryo Samples Received
                          </div>
                        </DropdownItem>
                      </IfAuthSuperAdminOrReadonly>
                    </DropdownMenu>
                  </Dropdown>
                </Col>
                <Col md="auto">
                  <Button onClick={() => this.onFilterApply()}>Apply Filter</Button>{' '}
                  <Button onClick={() => this.onFilterClear()}>Clear</Button>
                </Col>
              </Row>
              <IfAuthSuperAdminOrReadonly>
                <Row>
                  <table cellSpacing={6} style={{marginTop: "20px", marginBottom: "20px", borderCollapse: 'separate'}}>
                    <tbody>
                    <tr>
                      <td style={{textAlign: 'right'}}>
                        Consent Signed?&nbsp;
                      </td>
                      <td>
                        <label style={{cursor: 'pointer', marginBottom: 0}}>
                          <input
                            type="radio"
                            checked={this.state.consentSignedFlag === true}
                            onChange={() => this.setConsentSignedFlag(true)}
                          />
                          <span style={{marginLeft: '2px'}}>Yes</span>
                        </label>
                      </td>
                      <td>
                        <label style={{cursor: 'pointer', marginBottom: 0}}>
                          <input
                            type="radio"
                            checked={this.state.consentSignedFlag === false}
                            onChange={() => this.setConsentSignedFlag(false)}
                            style={{marginLeft: "5px"}}
                          />
                          <span style={{marginLeft: '2px'}}>No</span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td style={{textAlign: 'right'}}>
                        Invoice Paid? &nbsp;
                      </td>
                      <td>
                        <label style={{cursor: 'pointer', marginBottom: 0}}>
                          <input
                            type="radio"
                            checked={this.state.invoicePaidFlag === true}
                            onChange={() => this.setInvoicePaidFlag(true)}
                          />
                          <span style={{marginLeft: '2px'}}>Yes</span>
                        </label>
                      </td>
                      <td>
                        <label style={{cursor: 'pointer', marginBottom: 0}}>
                          <input
                            type="radio"
                            checked={this.state.invoicePaidFlag === false}
                            onChange={() => this.setInvoicePaidFlag(false)}
                            style={{marginLeft: "5px"}}
                          />
                          <span style={{marginLeft: '2px'}}>No</span>
                        </label>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </Row>
              </IfAuthSuperAdminOrReadonly>
            </div>

            <PageContent>
              {
                items ? (
                  <div>
                    {
                      items.length > 0 ? (
                        <Card>
                          <CardHeader>
                            <Row>
                              <Col>
                              <h6 style={{margin: 0}}>Total: {this.props.items_total}</h6>
                              </Col>
                              <Col md="auto">
                                <ReactPaginate
                                  previousLabel={'previous'}
                                  nextLabel={'next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={this.props.pages}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={5}
                                  onPageChange={(data) => {
                                    this.setState({
                                      ...this.state,
                                      current_page: data.selected + 1
                                    }, () => this.loadItems())
                                  }}
                                  containerClassName={'pagination'}
                                  subContainerClassName={'pages pagination'}
                                  activeClassName={'active'}
                                  initialPage={parseInt(this.state.current_page) - 1}
                                  disableInitialCallback={true}
                                />
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <div className="table-container">
                              <Table responsive hover>
                                <thead>
                                <tr>
                                  <th style={{width: 200}}>Patient, Partner</th>
                                  <th>Type</th>
                                  <th>Barcode ID</th>
                                  <th>Status</th>
                                  <IfAuthSuper2 component={'th'}>
                                    TaT
                                  </IfAuthSuper2>
                                  <th>Payment</th>
                                  <th>Samples</th>
                                  <th>Saliva Samples</th>
                                  <th>Consent Form</th>
                                  <th style={{width: 160}}>Created</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                  items.filter(item => !item.is_archived).map((item, i) => {
                                      return (
                                        <tr key={item.id} onClick={(e) => {
                                          window.location = '/patients/' + item.id + '?provider=' + item.provider
                                        }}>
                                          {/*<IfAuthSuper2 component={'td'}>*/}
                                          {/*  {item.provider_company_name}*/}
                                          {/*</IfAuthSuper2>*/}

                                          <td>
                                            <strong>
                                              {
                                                item.patient ?
                                                <span>
                                                  {item.patient.first_name}&nbsp;{item.patient.last_name}
                                                </span> : 'No Patient'
                                              }
                                              {
                                                item.partner && ', '
                                              }
                                              {
                                                item.partner ?
                                                <span>
                                                  {item.partner.first_name}&nbsp;{item.partner.last_name}
                                                </span> : ''
                                              }
                                            </strong>

                                            <IfAuthSuper2 component={'div'}>
                                              <div
                                                className={'text-muted'}
                                                style={{
                                                  fontSize: 12,
                                                  whiteSpace: 'nowrap',
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  width: 200
                                                }}
                                              >
                                                {item.provider_company_name}
                                              </div>
                                            </IfAuthSuper2>
                                          </td>

                                          <td>
                                            <div style={{minWidth: 60}}>
                                              {!item.m2 && <span>A{item.pgt_a_plus && '+'} </span>}
                                              {item.pgt_m && ' M '}
                                              {item.pgt_p && ' P '}
                                              {item.pgt_s && ' SR '}
                                              {item.m2 && ' M2 '}
                                            </div>

                                          </td>

                                          <td>
                                            {item.case_id ? <strong>{item.case_id}</strong> : '-'}
                                          </td>

                                          <td>
                                            <div style={{minWidth: 120}}>
                                              {item.case_status === 'pending' &&
                                              <span className="text-muted"><FontAwesomeIcon
                                                icon="stopwatch"/> Pending</span>}
                                              {item.case_status === 'processing' &&
                                              <span className="text-warning"><FontAwesomeIcon
                                                icon="sync"/> Processing</span>}
                                              {isVisibleForGPOnly(item.case_status) && !isAuthSuperAdminOrReadonly(this.props.user) &&
                                              <span className="text-warning"><FontAwesomeIcon
                                                icon="sync"/> Processing</span>}
                                              {item.case_status === 'canceled' &&
                                              <span className="text-warning"><FontAwesomeIcon
                                                icon="ban"/> Canceled</span>}
                                              {item.case_status === 'complete' &&
                                              <span className="text-success"><FontAwesomeIcon
                                                icon="check"/> Complete</span>}
                                              {item.case_status === 'sample_hold' && isAuthSuperAdminOrReadonly(this.props.user) &&
                                              <span className="text-muted"><FontAwesomeIcon
                                                icon="stopwatch" /> Sample Hold</span>}
                                              {item.case_status === 'report_hold' && isAuthSuperAdminOrReadonly(this.props.user) &&
                                              <span className="text-muted"><FontAwesomeIcon
                                                icon="stopwatch" /> Report Hold</span>}
                                              {item.case_status === 'report_ready' && isAuthSuperAdminOrReadonly(this.props.user) &&
                                              <span className="text-muted"><FontAwesomeIcon
                                                icon="file" /> Report Ready</span>}
                                              {item.case_status === 'case_accepted' && isAuthSuperAdminOrReadonly(this.props.user) &&
                                              <span className="text-muted"><FontAwesomeIcon
                                                icon="sync" /> Case Accepted</span>}
                                              {item.case_status === 'gc_scheduled' && isAuthSuperAdminOrReadonly(this.props.user) &&
                                              <span className="text-muted"><FontAwesomeIcon
                                                icon="sync" /> GC Scheduled</span>}
                                              {item.case_status === 'setup_complete' && isAuthSuperAdminOrReadonly(this.props.user) &&
                                              <span className="text-muted"><FontAwesomeIcon
                                                icon="sync" /> Set Up Complete</span>}
                                              {item.case_status === 'embryo_samples_received' && isAuthSuperAdminOrReadonly(this.props.user) &&
                                              <span className="text-muted"><FontAwesomeIcon
                                                icon="sync" /> Embryo Samples Received</span>}
                                            </div>
                                          </td>

                                          <IfAuthSuper2 component={'td'}>
                                            {(item.turn_around_time && item.turn_around_time !== '0') ? (
                                              <span>{item.turn_around_time}</span>) : '-'}
                                          </IfAuthSuper2>

                                          <td>
                                            <div style={{minWidth: 100}}>
                                              {
                                                item.case_payment_status === 'awaiting' && (
                                                  <span className="text-muted">
                                                    <FontAwesomeIcon icon="stopwatch"/> Awaiting
                                                  </span>
                                                )
                                              }
                                              {
                                                item.case_payment_status === 'received' && (
                                                  <span className="text-success">
                                                    <FontAwesomeIcon icon="check"/> Received
                                                  </span>
                                                )
                                              }
                                              {
                                                item.case_payment_status === 'no_bill' && (
                                                  <span className="text-warning">
                                                    <FontAwesomeIcon icon="check"/> Do Not Bill
                                                  </span>
                                                )
                                              }
                                            </div>
                                          </td>

                                          <td>
                                            {item.samples_count > 0 ? item.samples_count : '-'}
                                          </td>

                                          <td>
                                            {item.saliva_samples_count > 0 ? item.saliva_samples_count : '-'}
                                          </td>

                                          <td>
                                            {
                                              item.consent_form ?
                                                <FontAwesomeIcon icon="check" className="text-success"/> :
                                                <FontAwesomeIcon icon="exclamation-triangle" className="text-warning"/>
                                            }
                                          </td>

                                          <td>
                                            {/*<TimeAgo value={item.created_at}/><br/>*/}
                                            {moment.utc(item.created_at, 'YYYY-MM-DD hh:mm:ss').local().format('h:mm a')}<br/>
                                            {moment.utc(item.created_at, 'YYYY-MM-DD hh:mm:ss').local().format('MMM Do YYYY')}
                                            {/*<p>{item.created_at}</p>*/}
                                          </td>
                                        </tr>
                                      )
                                    }
                                  )
                                }
                                </tbody>
                              </Table>
                            </div>
                          </CardBody>
                        </Card>
                      ) : <p>No cases</p>
                    }
                  </div>
                ) : <TablePlaceholder rowHeight={46}/>
              }
            </PageContent>

            <Dialog
              className="new-case-modal"
              icon="plus"
              title="New Case"
              isOpen={this.state.newCaseModalIsOpen}
              onClose={() => {
                this.setState({...this.state, newCaseModalIsOpen: false})
              }}
            >
              <div className={Classes.DIALOG_BODY}>
                <Form
                  onSubmit={(values) => {
                    submit(values).then(handleResponse);
                  }}
                  onCancel={() => this.setState({...this.state, newCaseModalIsOpen: false})}
                  providerDefaults={this.state.providerDefaults}
                  onOverrideDefaultValues={onOverrideDefaultValues}
                  initialValues={{}}
                  responseErrors={this.state.responseErrorMessages}
                  clinicianList={this.state.clinicianList}
                  nurseList={this.state.nurseList}
                />
              </div>
            </Dialog>
          </Page>
        )
      }
    }
  )
)

