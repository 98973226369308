import React from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import {Button, Col, FormFeedback, FormGroup, FormText, Label, Row} from "reactstrap";
import classNames from "classnames";
import {IfAuthCanReport, IfAuthM2, IfAuthSuper} from "components/RBAC";
import {DISEASES} from 'constants/index';
import moment from 'moment';

import "./CaseForm.css"

// const Schema = Yup.object().shape({
//   diseases: Yup.string().nullable().test('too-few',
//     'At least one condition must be selected',
//     (value) => {
//       if (value) {
//         const parsed_value = JSON.parse(value);
//         return parsed_value.length > 0;
//       }
//       return true;
//     })
// });

/**
 * Case (test-section) editing form
 * @param {object} props
 *        initialValues - patient (case) object being edited.  Empty when creating new case.
 *        responseErrors - Error messages, if system unable to create case (usually dup case id)
 *        providerDefaults - default values from clinic when case is being created. e.g. report_embryo_id
 *        onOverrideDefaultValues - handler for saving overrides to default values
 *        clinicianList - list of doctors/providers attached to clinic
 *        nurseList - list of nurses attached to clinic
 */
export default (props) => {
  const { initialValues, responseErrors, providerDefaults, onOverrideDefaultValues } = props;
  
  const createInitialBoolean = (val, defaultValue = false) => {
    return (typeof val === 'undefined' || val === null) ? defaultValue : val
  }

  const initialEmptyBoolean = (val) => {
    if (typeof val === 'undefined' || val === null) return '';
    return val ? 'Y' : 'N';
  }

  // clinician required.  hide_sex required if not m2.
  const validationSchema = Yup.object().shape({
    clinician: Yup.string().required('Choice Required'),
    m2: Yup.boolean(),
    hide_sex: Yup
      .string()
      .when("m2", (m2, schema) => {
        if (!m2)
          return schema.required('Choice Required')
        return schema;
      })
  });

  return (
    <Formik
      initialValues={{  // new case: set values to false, '' or defaults;  existing case: copy values from current
        id: initialValues.id,
        case_id: initialValues.case_id,
        pgt_a: !initialValues.m2,            // new: true
        pgt_m: initialValues.pgt_m || false,
        pgt_p: initialValues.pgt_p || false,
        pgt_s: initialValues.pgt_s || false,
        pgt_a_plus: initialValues.pgt_a_plus || false,
        gseq: initialValues.gseq || false,
        m2: initialValues.m2 || false,
        pgt_m_target: initialValues.pgt_m_target,
        pgt_m_target_code: initialValues.pgt_m_target_code,
        clinician: initialValues.clinician || '',
        nurse: initialValues.nurse || '',

        // JSON string.  Set to DISEASES when new, or existing array is empty.
        diseases: initialValues.diseases === '[]' || !initialValues.diseases ? JSON.stringify(DISEASES) : initialValues.diseases,
        panel: initialValues.panel || 'comprehensive',
        case_status: initialValues.case_status || 'pending',
        case_payment_status: initialValues.case_payment_status || 'awaiting',

        hide_sex: createInitialBoolean(initialValues.hide_sex, ''),
        cycle_number: initialValues.cycle_number || '',
        conventional_ivf: createInitialBoolean(initialValues.conventional_ivf),
        icsi: createInitialBoolean(initialValues.icsi),
        egg_donor: createInitialBoolean(initialValues.egg_donor),
        sperm_donor: createInitialBoolean(initialValues.sperm_donor),
        keep_completion_date: createInitialBoolean(true),
        gc_pretest_date: initialValues.gc_pretest_date,
        gc_posttest_date: initialValues.gc_posttest_date,

        // new: use provider settings;  existing uses current case settings because no providerDefaults passed for that
        report_embryo_id: providerDefaults ? providerDefaults.report_embryo_id : initialValues.report_embryo_id,
        report_embryo_grade: providerDefaults ? providerDefaults.report_embryo_grade : initialValues.report_embryo_grade,
        report_biopsy_day: providerDefaults ? providerDefaults.report_biopsy_day : initialValues.report_biopsy_day,
        report_cycle_number: providerDefaults ? providerDefaults.report_cycle_number : initialValues.report_cycle_number,

        expected_egg_retrieval: initialValues.expected_egg_retrieval,
        completed_at: initialValues.completed_at,
        // rebiopsy_date: initialValues.rebiopsy_date,
        rebiopsy_flag: createInitialBoolean(initialValues.rebiopsy_flag),
        billing_type: initialValues.billing_type,
        samples_received: initialValues.samples_received,
        saliva_received_date: initialValues.saliva_received_date,
        reported_at: initialValues.reported_at,
        test_requested_at: initialValues.test_requested_at,

        blinded: initialValues.blinded,
        client_reference: initialValues.client_reference,
        clinical_trial_id: initialValues.clinical_trial_id,
        report_sent_date: initialValues.report_sent_date,
        consent_signed_date: initialValues.consent_signed_date,
        consented_research: initialEmptyBoolean(initialValues.consented_research),
        consented_sample_retention: initialEmptyBoolean(initialValues.consented_sample_retention),
        setup_completed_date: initialValues.setup_completed_date,
        study: initialValues.study || '',
        study_notes: initialValues.study_notes || ''
      }}

      validationSchema={validationSchema}

      onSubmit={
        (values, {setSubmitting}) => {
          values.case_id = values.case_id || null;
          values.samples_received = values.samples_received ? moment(values.samples_received).format('YYYY-MM-DD') : null
          values.saliva_received_date = values.saliva_received_date ? moment(values.saliva_received_date).format('YYYY-MM-DD') : null
          values.expected_egg_retrieval = values.expected_egg_retrieval ? moment(values.expected_egg_retrieval).format('YYYY-MM-DD') : null
          values.reported_at = values.reported_at ? moment(values.reported_at).format('YYYY-MM-DD') : null
          values.test_requested_at = values.test_requested_at ? moment(values.test_requested_at).format('YYYY-MM-DD') : null
          values.completed_at = values.completed_at ? moment(values.completed_at).format('YYYY-MM-DD') : null
          // values.rebiopsy_date = values.rebiopsy_date ? moment(values.rebiopsy_date).format('YYYY-MM-DD') : null
          values.report_sent_date = values.report_sent_date ? moment(values.report_sent_date).format('YYYY-MM-DD') : null
          values.consent_signed_date = values.consent_signed_date ? moment(values.consent_signed_date).format('YYYY-MM-DD') : null
          values.gc_pretest_date = values.gc_pretest_date ? moment(values.gc_pretest_date).format('YYYY-MM-DD') : null
          values.gc_posttest_date = values.gc_posttest_date ? moment(values.gc_posttest_date).format('YYYY-MM-DD') : null
          values.setup_completed_date = values.setup_completed_date ? moment(values.setup_completed_date).format('YYYY-MM-DD') : null
          values.cycle_number = values.report_cycle_number ? values.cycle_number : ''
          if (providerDefaults) {
            if (providerDefaults.report_embryo_id !== values.report_embryo_id) {
              onOverrideDefaultValues('report_embryo_id', values.report_embryo_id)
            }
            if (providerDefaults.report_embryo_grade !== values.report_embryo_grade) {
              onOverrideDefaultValues('report_embryo_grade', values.report_embryo_grade)
            }
            if (providerDefaults.report_cycle_number !== values.report_cycle_number) {
              onOverrideDefaultValues('report_cycle_number', values.report_cycle_number)
            }
          }     
          props.onSubmit(values);
          setSubmitting(false)
        }
      }
    >
      {
        ({errors, touched, values, isSubmitting, setFieldValue, handleChange}) => {
          return (
            <Form autoComplete="off">
              <div>Required fields marked with <span style={{color: 'red'}}>*</span></div>
              <div>&nbsp;</div>
              <IfAuthCanReport>
              <FormGroup row>
                  <Label for="case_id" sm={4}>GPCL Barcode ID</Label>
                  <Col sm={8}>
                    <Field
                      type="text"
                      name="case_id"
                      className={classNames('form-control', {
                        'is-invalid': touched.case_id && (!!errors.case_id || !!responseErrors.case_id)
                      })}
                    />
                    {!!responseErrors.case_id &&
                      <FormFeedback>
                        GPCL Barcode ID already exists
                      </FormFeedback>
                    }
                    <FormText>5 digit code-00</FormText>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="client_reference" sm={4}>Client Reference</Label>
                  <Col sm={8}>
                    <Field
                      type="text"
                      name="client_reference"
                      className={classNames('form-control', {
                        'is-invalid': touched.client_reference && !!errors.client_reference
                      })}
                    />
                    <FormFeedback>
                      <ErrorMessage name="client_reference" component="span"/>
                    </FormFeedback>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="clinical_trial_id" sm={4}>Clinical Trial ID</Label>
                  <Col sm={8}>
                    <Field
                      type="text"
                      name="clinical_trial_id"
                      className={classNames('form-control', {
                        'is-invalid': touched.clinical_trial_id && !!errors.clinical_trial_id
                      })}
                    />
                    <FormFeedback>
                      <ErrorMessage name="clinical_trial_id" component="span"/>
                    </FormFeedback>
                  </Col>
                </FormGroup>
              </IfAuthCanReport>

              <FormGroup row>
                <Label sm={4}>Test Type<span style={{color:'red'}}>*</span></Label>
                <Col sm={8}>
                  <FormGroup check inline>
                    <Label check>
                      <Field
                        name="pgt_a"
                        render={({field, form: {isSubmitting}}) => (
                          <input
                            {...field}
                            disabled={!values.m2}
                            type="checkbox"
                            checked={field.value}
                            onChange={(e) => {
                              handleChange(e);
                              if (e.target.checked) {
                                values.m2 = false;
                              }
                            }}
                          />
                        )}
                      />{' '}
                      PGT-A
                    </Label>
                  </FormGroup>

                  <FormGroup check inline>
                    <Label check>
                      <Field
                        name="pgt_a_plus"
                        render={({field, form: {isSubmitting}}) => (
                          <input
                            {...field}
                            disabled={isSubmitting}
                            type="checkbox"
                            checked={field.value}
                            onChange={(e) => {
                              handleChange(e);
                              if (e.target.checked) {
                                values.m2 = false;
                                values.pgt_a = true;
                              }
                            }}
                          />
                        )}
                      />{' '}
                      PGT-A+
                    </Label>
                  </FormGroup>

                  <FormGroup check inline>
                    <Label check>
                      <Field
                        name="pgt_m"
                        render={({field, form: {isSubmitting}}) => (
                          <input
                            {...field}
                            disabled={isSubmitting}
                            type="checkbox"
                            checked={field.value}
                            onChange={(e) => {
                              handleChange(e);
                              if (e.target.checked) {
                                values.m2 = false;
                                values.pgt_a = true;
                              }
                            }}
                          />
                        )}
                      />{' '}
                      PGT-M
                    </Label>
                  </FormGroup>

                  <FormGroup check inline>
                    <Label check>
                      <Field
                        name="pgt_p"
                        render={({field, form: {isSubmitting}}) => (
                          <input
                            {...field}
                            disabled={isSubmitting}
                            type="checkbox"
                            checked={field.value}
                            onChange={(e) => {
                              handleChange(e);
                              if (e.target.checked) {
                                values.m2 = false;
                                values.pgt_a = true;
                              }
                            }}
                          />
                        )}
                      />{' '}
                      PGT-P
                    </Label>
                  </FormGroup>

                  <FormGroup check inline>
                    <Label check>
                      <Field
                        name="pgt_s"
                        render={({field, form: {isSubmitting}}) => (
                          <input
                            {...field}
                            disabled={isSubmitting}
                            type="checkbox"
                            checked={field.value}
                            onChange={(e) => {
                              handleChange(e);
                              if (e.target.checked) {
                                values.m2 = false;
                                values.pgt_a = true;
                              }
                            }}
                          />
                        )}
                      />{' '}
                      PGT-SR
                    </Label>
                  </FormGroup>

                  <IfAuthM2>
                    <FormGroup check inline>
                      <Label check>
                        <Field
                          name="m2"
                          render={({field, form: {isSubmitting}}) => (
                            <input
                              {...field}
                              disabled={isSubmitting}
                              type="checkbox"
                              checked={field.value}
                              onChange={(e) => {
                                handleChange(e);
                                if (e.target.checked) {
                                  values.pgt_a = false;
                                  values.pgt_a_plus = false;
                                  values.pgt_m = false;
                                  values.pgt_p = false;
                                  values.pgt_s = false;
                                }
                              }}
                            />
                          )}
                        />{' '}
                        M2
                      </Label>
                    </FormGroup>
                  </IfAuthM2>
                </Col>
              </FormGroup>

              <IfAuthM2>
                {
                  values.m2 && (
                    <FormGroup row className="align-items-center">
                      <Label for="blinded" sm={4}>M2 Blinded</Label>
                      <Col sm={8}>
                        <Field
                          name="blinded"
                          component="select"
                          className="form-control"
                        >
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </Field>
                      </Col>
                    </FormGroup>
                  )
                }
              </IfAuthM2>

              <FormGroup row className="align-items-center">
                <Label for="clinician" sm={4}>Referring Physician<span style={{color: 'red'}}>*</span></Label>
                <Col sm={8}>
                {
                    props.clinicianList ? (
                      <>
                        <Field
                          name="clinician"
                          component="select"
                          className="form-control"
                        >
                          <option value={''}>Select</option>
                          {
                            props.clinicianList.map((item) =>
                              <option value={item.id}
                                      key={`select-clinician-${item.id}`}>{item.first_name} {item.last_name}</option>
                            )
                          }
                        </Field>
                        {touched.clinician && errors.clinician && <div style={{color:'red'}}>{errors.clinician}</div>}
                      </>
                    ) : (
                      <span>Loading...</span>
                    )
                  }
                </Col>
              </FormGroup>

              <FormGroup row className="align-items-center">
                <Label for="nurse" sm={4}>Nurse</Label>
                <Col sm={8}>
                  {
                    props.nurseList ? (
                      <Field
                        name="nurse"
                        component="select"
                        className="form-control"
                      >
                        <option value={''}>Select</option>
                        {
                          props.nurseList.map((item) =>
                            <option value={item.id}
                                    key={`select-nurse-${item.id}`}>{item.first_name} {item.last_name}</option>
                          )
                        }
                      </Field>
                    ) : (
                      <span>Loading...</span>
                    )
                  }

                </Col>
              </FormGroup>

              {
                values.pgt_p && (
                  <FormGroup row>
                  <Label for="panel" sm={4}>Panel</Label>
                  <Col sm={8}>
                    <Field
                      name="panel"
                      component="select"
                      className={classNames('form-control', {
                        'is-invalid': touched.panel && !!errors.panel
                      })}
                    >
                      <option value="comprehensive">Comprehensive</option>
                      <option value="custom">Custom</option>
                    </Field>
                    <FormFeedback>
                      <ErrorMessage name="panel" component="span"/>
                    </FormFeedback>
                  </Col>
                </FormGroup>
                )
              }

              <div style={{marginBottom: 12}}>
                {errors.diseases ? (
                  <span className="text-danger">{errors.diseases}</span>
                ) : null}
              </div>

              {
                !values.m2 && (
                  <div>
                    {/*<IfAuthSuper>*/}
                    {/*  <FormGroup row className="align-items-center">*/}
                    {/*    <Label for="gseq" sm={4}>gSEQ</Label>*/}
                    {/*    <Col sm={8}>*/}
                    {/*      <Field*/}
                    {/*        name="gseq"*/}
                    {/*        className="form-control"*/}
                    {/*        render={({field, form: {isSubmitting}}) => (*/}
                    {/*          <input {...field} disabled={isSubmitting} type="checkbox" checked={field.value}/>*/}
                    {/*        )}*/}
                    {/*      />*/}
                    {/*    </Col>*/}
                    {/*  </FormGroup>*/}
                    {/*</IfAuthSuper>*/}

                    {/*<IfAuthCanReport>*/}
                    {/*  <FormGroup row className="align-items-center">*/}
                    {/*    <Label for="gseq" sm={4}>gSEQ</Label>*/}
                    {/*    <Col sm={8}>*/}
                    {/*      <Field*/}
                    {/*        name="gseq"*/}
                    {/*        className="form-control"*/}
                    {/*        render={({field, form: {isSubmitting}}) => (*/}
                    {/*          <input {...field} disabled={isSubmitting} type="checkbox" checked={field.value}/>*/}
                    {/*        )}*/}
                    {/*      />*/}
                    {/*    </Col>*/}
                    {/*  </FormGroup>*/}
                    {/*</IfAuthCanReport>*/}

                    <FormGroup row className="align-items-center">
                      <Label for="hide_sex" sm={4}>Sex of Embryo<span style={{color: 'red'}}>*</span></Label>
                      <Col sm={8}>
                      <Field
                          name="hide_sex"
                          component="select"
                          className="form-control"
                        >
                          <option value={''}>Not Selected</option>
                          <option value={true}>Do Not Report</option>
                          <option value={false}>Report</option>
                        </Field>
                      {touched.hide_sex && errors.hide_sex && <div style={{color:'red'}}>{errors.hide_sex}</div>}
                      </Col>
                    </FormGroup>
                    {initialValues.provider_report_embryo_id && (
                      <FormGroup row className="align-items-center">
                        <Label for="report_embryo_id" sm={4}>Report Embryo ID</Label>
                        <Col sm={8}>
                          <Field
                            name="report_embryo_id"
                            className="form-control"
                            render={({field, form: {isSubmitting}}) => (
                              <input {...field} disabled={isSubmitting} type="checkbox" checked={field.value}/>
                            )}
                          />
                        </Col>
                      </FormGroup>
                    )}
                    <FormGroup row className="align-items-center">
                      <Label for="report_embryo_grade" sm={4}>Report Embryo Grade</Label>
                      <Col sm={8}>
                        <Field
                          name="report_embryo_grade"
                          className="form-control"
                          render={({field, form: {isSubmitting}}) => (
                            <input {...field} disabled={isSubmitting} type="checkbox" checked={field.value}/>
                          )}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label for="report_cycle_number" sm={4}>Report Cycle #</Label>
                      <Col sm={8}>
                        <Field
                          name="report_cycle_number"
                          className="form-control"
                          render={({field, form: {isSubmitting}}) => (
                            <input {...field} disabled={isSubmitting} type="checkbox" checked={field.value}/>
                          )}
                        />
                      </Col>
                    </FormGroup>
                    {
                      values.report_cycle_number && (
                        <FormGroup row className="align-items-center">
                          <Label for="cycle_number" sm={4}>Cycle #</Label>
                          <Col sm={8}>
                            <Field
                              type="text"
                              name="cycle_number"
                              className={classNames('form-control', {
                                'is-invalid': touched.cycle_number && !!errors.cycle_number
                              })}
                            />
                            <FormFeedback>
                              <ErrorMessage name="clinical_trial_id" component="span"/>
                            </FormFeedback>
                          </Col>
                        </FormGroup>
                      )
                    }
                    <FormGroup row className="align-items-center">
                      <Label for="conventional_ivf" sm={4}>Conventional IVF</Label>
                      <Col sm={8}>
                        <Field
                          name="conventional_ivf"
                          className="form-control"
                          render={({field, form: {isSubmitting}}) => (
                            <input {...field} disabled={isSubmitting} type="checkbox" checked={field.value}/>
                          )}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup row className="align-items-center">
                      <Label for="icsi" sm={4}>ICSI</Label>
                      <Col sm={8}>
                        <Field
                          name="icsi"
                          className="form-control"
                          render={({field, form: {isSubmitting}}) => (
                            <input {...field} disabled={isSubmitting} type="checkbox" checked={field.value}/>
                          )}
                        />
                      </Col>
                    </FormGroup>
                  </div>
                )
              }

              {
                !values.m2 && (
                  <div>
                    <FormGroup row className="align-items-center">
                      <Label for="egg_donor" sm={4}>Egg Donor</Label>
                      <Col sm={8}>
                        <Field
                          name="egg_donor"
                          className="form-control"
                          render={({field, form: {isSubmitting}}) => (
                            <input {...field} disabled={isSubmitting} type="checkbox" checked={field.value}/>
                          )}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                      <Label for="sperm_donor" sm={4}>Sperm Donor</Label>
                      <Col sm={8}>
                        <Field
                          name="sperm_donor"
                          className="form-control"
                          render={({field, form: {isSubmitting}}) => (
                            <input {...field} disabled={isSubmitting} type="checkbox" checked={field.value}/>
                          )}
                        />
                      </Col>
                    </FormGroup>
                  </div>
                )
              }

              <IfAuthSuper>
                <FormGroup row>
                  <Label for="case_status" sm={4}>Case Status</Label>
                  <Col sm={8}>
                    <Field
                      name="case_status"
                      component="select"
                      className={classNames('form-control', {
                        'is-invalid': touched.case_status && !!errors.case_status
                      })}
                    >
                      <option value="pending">Pending</option>
                      <option value="processing">Processing</option>
                      <option value="complete">Complete</option>
                      <option value="canceled">Canceled</option>
                      <option value="sample_hold">Sample Hold</option>
                      <option value="report_hold">Report Hold</option>
                      <option value="report_ready">Report Ready</option>
                      <option value="case_accepted">Case Accepted</option>
                      <option value="gc_scheduled">GC Scheduled</option>
                      <option value="setup_complete">Set Up Complete</option>
                      <option value="embryo_samples_received">Embryo Samples Received</option>
                    </Field>
                    <FormFeedback>
                      <ErrorMessage name="case_status" component="span"/>
                    </FormFeedback>
                  </Col>
                  </FormGroup>
                {initialValues.completed_at && initialValues.case_status !== 'complete' && values.case_status === 'complete' && (
                  <FormGroup row className="align-items-center">
                    <Label for="keep_completion_date" sm={4}>Keep {moment(initialValues.completed_at).format('MM/DD/YYYY')} Completed Date</Label>
                    <Col sm={8}>
                      <Field
                        name="keep_completion_date"
                        className="form-control"
                        render={({field, form: {isSubmitting}}) => (
                          <input {...field} disabled={isSubmitting} type="checkbox" checked={field.value}/>
                        )}
                      />
                    </Col>
                  </FormGroup> )}
              </IfAuthSuper>

              <IfAuthSuper>
                <FormGroup row>
                  <Label for="case_payment_status" sm={4}>Payment Status</Label>
                  <Col sm={8}>
                    <Field
                      name="case_payment_status"
                      component="select"
                      className={classNames('form-control', {
                        'is-invalid': touched.case_payment_status && !!errors.case_payment_status
                      })}
                    >
                      <option value="awaiting">Awaiting</option>
                      <option value="received">Received</option>
                      <option value="no_bill">Do Not Bill</option>
                    </Field>
                    <FormFeedback>
                      <ErrorMessage name="case_payment_status" component="span"/>
                    </FormFeedback>
                  </Col>
                </FormGroup>
              </IfAuthSuper>

              {
                !values.m2 && (
                  <div>
                    <FormGroup row>
                      <Label for="expected_egg_retrieval" sm={4}>Expected Egg Retrieval/Thaw Date</Label>
                      <Col sm={8}>
                        <input
                          type="date"
                          id="expected_egg_retrieval"
                          name="expected_egg_retrieval"
                          className={classNames('form-control', {
                            'is-invalid': touched.expected_egg_retrieval && !!errors.expected_egg_retrieval
                          })}
                          onChange={(d) => {setFieldValue('expected_egg_retrieval', d.target.value)}}
                          value={values.expected_egg_retrieval || undefined}
                        />
                        <FormFeedback>
                          <ErrorMessage name="expected_egg_retrieval" component="span"/>
                        </FormFeedback>
                      </Col>
                    </FormGroup>
                  </div>
                )
              }

              <FormGroup row>
                <Label for="billing_type" sm={4}>Billing Type</Label>
                <Col sm={8}>
                  <Field
                    component="select"
                    name="billing_type"
                    className={classNames('form-control', {
                      'is-invalid': touched.billing_type && !!errors.billing_type
                    })}
                  >
                    <option value={''}>Select</option>
                    <option value="Client Bill">Client Bill</option>
                    <option value="Patient Bill">Patient Bill</option>
                    <option value="Progyny Bill">Progyny Bill</option>
                    <option value="Split Bill">Split Bill</option>
                    <option value="Maven">Maven</option>
                    <option value="WINfertility">WINFertility</option>
                    <option value="other">other</option>
                  </Field>
                  <FormFeedback>
                    <ErrorMessage name="billing_type" component="span" />
                  </FormFeedback>
                </Col>
              </FormGroup>

              <IfAuthCanReport>
                <FormGroup row>
                  <Label for="samples_received" sm={4}>Samples Received Date</Label>
                  <Col sm={8}>
                    <input
                      type="date"
                      name="samples_received"
                      className={classNames('form-control', {
                        'is-invalid': touched.samples_received && !!errors.samples_received
                      })}
                      onChange={(d) => {setFieldValue('samples_received', d.target.value)}}
                      value={values.samples_received || undefined}
                    />
                    <FormFeedback>
                      <ErrorMessage name="samples_received" component="span"/>
                    </FormFeedback>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="saliva_received_date" sm={4}>Saliva Received Date</Label>
                  <Col sm={8}>
                    <input
                      type="date"
                      name="saliva_received_date"
                      className={classNames('form-control', {
                        'is-invalid': touched.saliva_received_date && !!errors.saliva_received_date
                      })}
                      onChange={(d) => {setFieldValue('saliva_received_date', d.target.value)}}
                      value={values.saliva_received_date || undefined}
                    />
                    <FormFeedback>
                      <ErrorMessage name="saliva_received_date" component="span"/>
                    </FormFeedback>
                  </Col>
                </FormGroup>
              </IfAuthCanReport>

              <IfAuthSuper>
                  <>
                    <FormGroup row>
                      <Label for="gc_pretest_date" sm={4}>GC Pre-Test Date</Label>
                      <Col sm={8}>
                        <input
                          type="date"
                          name="gc_pretest_date"
                          className={classNames('form-control', {
                            'is-invalid': touched.gc_pretest_date && !!errors.gc_pretest_date
                          })}
                          onChange={(d) => {setFieldValue('gc_pretest_date', d.target.value)}}
                          value={values.gc_pretest_date || undefined}
                        />
                        <FormFeedback>
                          <ErrorMessage name="gc_pretest_date" component="span"/>
                        </FormFeedback>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for="gc_posttest_date" sm={4}>GC Post-Test Date</Label>
                      <Col sm={8}>
                        <input
                          type="date"
                          name="gc_posttest_date"
                          className={classNames('form-control', {
                            'is-invalid': touched.gc_posttest_date && !!errors.gc_posttest_date
                          })}
                          onChange={(d) => {setFieldValue('gc_posttest_date', d.target.value)}}
                          value={values.gc_posttest_date || undefined}
                        />
                        <FormFeedback>
                          <ErrorMessage name="gc_posttest_date" component="span"/>
                        </FormFeedback>
                      </Col>
                    </FormGroup>
                  </>

                <FormGroup row>
                  <Label for="completed_at" sm={4}>Case Completed Date</Label>
                  <Col sm={8}>
                    <input
                      type="date"
                      name="completed_at"
                      className={classNames('form-control', {
                        'is-invalid': touched.completed_at && !!errors.completed_at
                      })}
                      onChange={(d) => {setFieldValue('completed_at', d.target.value)}}
                      value={values.completed_at || undefined}
                    />
                    <FormFeedback>
                      <ErrorMessage name="completed_at" component="span"/>
                    </FormFeedback>
                  </Col>
                </FormGroup>
                <FormGroup row className="align-items-center">
                  <Label for="rebiopsy_flag" sm={4}>Rebiopsy ?</Label>
                  <Col sm={8}>
                    <Field
                      name="rebiopsy_flag"
                      className="form-control"
                      render={({field, form: {isSubmitting}}) => (
                        <input {...field} disabled={isSubmitting} type="checkbox" checked={field.value}/>
                      )}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="report_sent_date" sm={4}>Report Sent Date</Label>
                  <Col sm={8}>
                    <input
                      type="date"
                      name="report_sent_date"
                      className={classNames('form-control', {
                        'is-invalid': touched.report_sent_date && !!errors.report_sent_date
                      })}
                      onChange={(d) => {setFieldValue('report_sent_date', d.target.value)}}
                      value={values.report_sent_date || undefined}
                    />
                    <FormFeedback>
                      <ErrorMessage name="report_sent_date" component="span"/>
                    </FormFeedback>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="consent_signed_date" sm={4}>Consent Signed Date</Label>
                  <Col sm={8}>
                    <input
                      type="date"
                      name="consent_signed_date"
                      className={classNames('form-control', {
                        'is-invalid': touched.consent_signed_date && !!errors.consent_signed_date
                      })}
                      onChange={(d) => {setFieldValue('consent_signed_date', d.target.value)}}
                      value={values.consent_signed_date || undefined}
                    />
                    <FormFeedback>
                      <ErrorMessage name="consent_signed_date" component="span"/>
                    </FormFeedback>
                  </Col>
                </FormGroup>

                <FormGroup row className="align-items-center">
                  <Label for="consented_research" sm={4}>Consented for Research ?</Label>
                  <Col sm={8}>
                    <Field
                      name="consented_research"
                      component="select"
                      className="form-control"
                    >
                      <option value={''}>Not Selected</option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </Field>
                  </Col>
                </FormGroup>

                <FormGroup row className="align-items-center">
                  <Label for="consented_sample_retention" sm={4}>Consented for Sample Retention ?</Label>
                  <Col sm={8}>
                    <Field
                      name="consented_sample_retention"
                      component="select"
                      className="form-control"
                    >
                      <option value={''}>Not Selected</option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </Field>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="setup_completed_date" sm={4}>Setup Completed Date</Label>
                  <Col sm={8}>
                    <input
                      type="date"
                      name="setup_completed_date"
                      className={classNames('form-control', {
                        'is-invalid': touched.setup_completed_date && !!errors.setup_completed_date
                      })}
                      onChange={(d) => {setFieldValue('setup_completed_date', d.target.value)}}
                      value={values.setup_completed_date || undefined}
                    />
                    <FormFeedback>
                      <ErrorMessage name="setup_completed_date" component="span"/>
                    </FormFeedback>
                  </Col>
                </FormGroup>
              </IfAuthSuper>

              <IfAuthSuper>
                <FormGroup row>
                  <Label for="study" sm={4}>Study</Label>
                  <Col sm={8}>
                    <Field
                      name="study"
                      component="select"
                      className={classNames('form-control', {
                        'is-invalid': touched.study && !!errors.study
                      })}
                    >
                      <option value={''}>Not Selected</option>
                      <option value="cfDNA">cfDNA</option>
                      <option value="GETSET (NCT04167748)">GETSET (NCT04167748)</option>
                      <optgroup label="EHS (NCT04528498)">
                        <option value="EHS (NCT04528498) Simultaneous">EHS Simultaneous</option>
                        <option value="EHS (NCT04528498) Sequential">EHS Sequential</option>
                        <option value="EHS (NCT04528498) Decline">EHS Decline</option>
                        <option value="EHS (NCT04528498) Pending">EHS Pending</option>
                        <option value="EHS (NCT04528498) Not Eligible">EHS Not Eligible</option>
                      </optgroup>
                      <option value="LifeView (NCT04477863)">LifeView (NCT04477863)</option>
                      <option value="M2 (NCT04544462)">M2 (NCT04544462)</option>
                    </Field>
                    <FormFeedback>
                      <ErrorMessage name="study" component="span"/>
                    </FormFeedback>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="study_notes" sm={4}>Study Notes</Label>
                  <Col sm={8}>
                    <Field
                      type="text"
                      name="study_notes"
                      className={classNames('form-control', {
                        'is-invalid': touched.study_notes && !!errors.study_notes
                      })}
                    />
                    <FormFeedback>
                      <ErrorMessage name="study_notes" component="span"/>
                    </FormFeedback>
                  </Col>
                </FormGroup>
              </IfAuthSuper>

              {
                !values.id &&
                <p className="text-muted">
                  By clicking the "Create Case" button, I confirm that I am ordering a PGT test for this patient,
                  referring
                  this patient for testing by GPCL.
                </p>
              }

              <Row style={{marginTop: 20}} className="justify-content-start">
                <Col md="auto">
                  <Button
                      type="submit"
                      color="success"
                      disabled={isSubmitting || !!Object.keys(errors).length}
                  >
                      Submit
                  </Button>
                </Col>
                <Col md="auto">
                    <Button color="light" onClick={() => props.onCancel()}>Cancel</Button>
                </Col>
              </Row>

            </Form>
          )
        }
      }
    </Formik>
  )
}
